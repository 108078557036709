import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {ExibeContrato} from './components/ExibeContrato';
import {ExibePadrao} from './components/ExibePadrao';
import {ExibeNotFound} from './components/ExibeNotFound';
import {ContratoEnviado} from './components/ContratoEnviado';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: true,
            overlayMenuActive: false,
            mobileMenuActive: false
        };

    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
         const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        return (
            <div className={wrapperClass}>
                <AppTopbar/>
                <div className="layout-main">
                    <BrowserRouter>
                        <Switch>
                            <Route path="/" exact name="Contrato" component={ExibePadrao} />
                            <Route path="/contrato/:id" name="Contrato" component={ExibeContrato} />
                            <Route path="/contratoEnviado" component={ContratoEnviado} />
                            <Route path="*" component={ExibeNotFound} />
                        </Switch>
                    </BrowserRouter>
                </div>

                <AppFooter />
                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;