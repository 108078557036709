import AxiosApi from './AxiosApi';
import { config } from '../Constants'

class ContratoService {
    
	getAuthToken() {
		const confAuth = {
            headers: {
                    'Content-Type' : 'application/json',
                    'accept' : 'application/json',
                    'Authorization' : "Basic " + Buffer.from(config.url.CLIENT_ID + ":" + config.url.ACCESS_TOKEN).toString('base64')					
                    }
         };		 		
		
		return AxiosApi.post(
            config.url.OAUTH_URL, 
            {"grant_type":"client_credentials"},
            confAuth)
            .then(res => res.data);
	}
	
    postContrato(token, access_token) {
		
		const conf = {
			  headers: {
					  'Content-Type' : 'application/json',
					  'accept' : 'application/json',
					  'client_id' : config.url.CLIENT_ID,
					  'access_token': access_token
				}
		};

		return AxiosApi.post(
			'/aceite-contrato'
			,{token: token}
			,conf
			).then(res => (res.data));											
        
    }
		
}

export default ContratoService;