let configint = "";

const prod = {
 url: {
    API_URL: 'https://api.tempoassist.com.br/mms/prestador/v1',	
    OAUTH_URL: "https://api.tempoassist.com.br/oauth2/access-token",
	CLIENT_ID: "b6423f5e-066c-3f15-8cbe-cbc1f263f39d",
	ACCESS_TOKEN: "bc32949b-613e-34a3-b960-750422530855"
 }
};

const dev = {
    url: {
       API_URL: 'https://api.tempoassist.com.br/sandbox/mms/prestador/v1',	  
       OAUTH_URL: "https://api.tempoassist.com.br/sandbox/oauth2/access-token",
	   CLIENT_ID: "b6423f5e-066c-3f15-8cbe-cbc1f263f39d",
	   ACCESS_TOKEN: "bc32949b-613e-34a3-b960-750422530855"
       }
   };
   

if (process.env.NODE_ENV === 'development'){
    configint = dev;
} else{
    configint = prod;
}

export const config = configint;
