import React, {Component} from 'react';

export class ExibePadrao extends Component {
    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <p>Bem vindo ao aplicativo de Contratos.</p>

                        <p>Para ler e aceitar um contrato, você precisa ser notificado antes.</p>

                        <p>Em caso de dúvidas, entre em contato com a central.</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default ExibePadrao;